/* eslint-disable max-len */
module.exports = {
  index: {
    desc: 'En oversikt over alle produktene',
    list: {
      loading: 'Laster inn produktene...',
      loadMore: 'Last inn flere produkter',
      missingName: '(Mangler produktnavn)',
      missingDesc: '(Mangler produktbeskrivelse)',
      customer: 'Sorter på kunde:'
    }
  },
  sync: {
    start: 'Start synkronisering',
    running: 'Synkroniserer...',
    last: 'Siste synkronisering ble fullført :date',
    desc: 'Synkroniser produktene med Viscenario',
    main: {
      title: '',
      desc: ''
    },
    item: {
      start: {
        title: 'Synkroniseringen startet',
        desc: 'Produktsynkroniseringen ble startet',
        status: 'Startet'
      },
      new: {
        title: 'Nytt produkt',
        desc: '',
        status: 'Nytt produkt'
      },
      error: {
        title: 'Feil oppstod',
        desc: '',
        status: 'Feil'
      },
      updated: {
        title: 'Oppdatert produkt',
        desc: '',
        status: 'Oppdatert',
        actions: {
          name: 'Navnet ble oppdatert fra :old til :new',
          description: 'Beskrivelsen ble oppdatert fra :old til :new',
          images: 'Bildene ble oppdatert!',
          extUpdatedOn: 'modifiedDate fra API ble endret fra :old til :new',
          removed: 'Produktet ble fjernet fra :templatename',
          added: 'Produktet ble lagt til templatet :templatename',
          missingParam: '(mangler parameter)',
          missingField: '(mangler felt)'
        },
        events: {
          images: {
            errors: {
              missingUrl: 'Mangler URL'
            }
          }
        }
      },
      errorUpdated: {
        title: 'Oppdateringsfeil',
        desc: '',
        status: 'Oppdateringsfeil'
      },
      unchanged: {
        title: 'Uendret',
        desc: '',
        status: 'Uendret'
      },
      end: {
        title: 'Synkroniseringen avsluttet',
        desc: '',
        status: 'Avsluttet'
      }
    },
    log: {
      title: 'Synkroniseringslogg',
      desc: 'En oversikt over tidligere utførte synkroniseringer',
      missingTitle: '[Mangler navn/tittel]',
      missingExtId: '[Mangler ekstern ID]',
      missingStatus: '[Mangler status]',
      btn: {
        loadMore: 'Last inn flere sykroniseringslogger'
      },
      item: {
        title: 'Log ID: :logId',
        desc: 'Detaljert oversikt over produktene som ble synkronisert',
        loading: 'Henter synkroniseringsdataen...'
      },
      items: {
        title: 'Detaljert liste over synkroniseringen',
        desc: 'Her vises hvert produkt som ble synkronisert',
        btn: {
          loadMore: 'Last inn flere'
        }
      }
    },
    started: 'Startet',
    ended: 'Fullført',
    progress: 'Progresjon',
    active: 'Synk aktiv...',
    total: 'Totalt',
    processed: 'Fullført',
    unchanged: 'Uendret',
    new: 'Nye',
    failed: 'Feilet',
    updated: 'Oppdaterte'
  }
}
